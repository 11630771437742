import React from "react";
import {SnackbarProvider} from "notistack";
import {SnackBarDuration} from "../services/SnackbarService";

export const notistackRef = React.createRef();

const SnackbarWrapper = ({children}) => {
    return (
        <SnackbarProvider
            ref={notistackRef}
            preventDuplicate
            maxSnack={1}
            hideIconVariant
            autoHideDuration={SnackBarDuration.Medium}
            anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
            }}
            classes={{
                root: 'snackbar-provider-container-root',
                variantInfo: 'snackbar-provider-variant-info',
                anchorOriginBottomCenter: 'snackbar-provider-anchor-origin-bottom-center',
                containerRoot: 'snackbar-provider-container-root',
            }}
        >
            {children}
        </SnackbarProvider>
    );
};

export default SnackbarWrapper;
