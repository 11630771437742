import { lazy } from 'react';

const lazyWithDefault = (importFunc) => {
    return lazy(() => importFunc().then(module => ({ default: module.default })));
};

const Dashboard = lazyWithDefault(() => import("../views/dashboard/Dashboard"));
const Profile = lazyWithDefault(() => import("../views/profile/Profile"));
const Product = lazyWithDefault(() => import("../views/product/Product"));
const Order = lazyWithDefault(() => import("../views/Order/Order"));

export {
    Profile,
    Dashboard,
    Product,
    Order,
}
