import { configureStore } from '@reduxjs/toolkit';
import userReducer from "../reducers/userReducer";
import productsReducer from "../reducers/productsReducer";
import orderReducer from "../reducers/orderReducer";

export const Store = configureStore({
    reducer: {
        user: userReducer,
        products: productsReducer,
        order: orderReducer,
    },
});
