import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationEN from '../locales/en/translation.json';
import translationFA from '../locales/fa/translation.json';
import StorageService from "../services/storage.service";

i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: translationEN },
            fa: { translation: translationFA }
        },
        lng: StorageService.get("language") || 'fa', // todo change the language based on user choice and change the direction according to it
        fallbackLng: 'fa',
        // debug: true,
        detection: {
            order: ['htmlTag', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'path', 'subdomain'],
            caches: ['cookie']
        },
        interpolation: {
            escapeValue: false
        }
    });

export default i18next;