import axios from 'axios';
import HttpMethod from "../enums/HttpMethode";
import TokenService from "./token.service";
import StorageService from "./storage.service";
import RoutesUrl from "../routes/RoutesUrl";
import {useNavigate} from "react-router-dom";



/**
 * @callback onFulfilledRequest
 * @param {AxiosRequestConfig} config
 */

/**
 * @callback onFulfilledResponse
 * @param {AxiosResponse} response
 */

const baseURL = 'https://api.noonomast.com/api/v1';

const instance = axios.create({
    baseURL,
});

class ApiService {
    /**
     * Set header for all or specific http method
     *
     * @param {String} name
     * @param {String} value
     * @param {String} [method]
     * @returns void
     */
    static setHeader(name, value, method) {
        if (!method) {
            instance.defaults.headers.common[name] = value;
            return;
        }

        if (!HttpMethod[method]) {
            throw new Error(`Invalid http method "${method}"`);
        }

        instance.defaults.headers[method][name] = value;
    }

    /**
     * Add request middleware
     *
     * @param {function(axios.AxiosResponse): axios.AxiosResponse} onFulfilled
     * @returns {Number} Middleware id
     */
    static addRequestMiddleware(onFulfilled) {
        return instance.interceptors.request.use(onFulfilled);
    }

    /**
     * Remove request middleware
     *
     * @param {Number} id
     * @returns void
     */
    static removeRequestMiddleware(id) {
        instance.interceptors.request.eject(id);
    }

    /**
     * Add response middleware
     *
     * @param {onFulfilledResponse} onFulfilled
     * @returns {Number} Middleware id
     */
    static addResponseMiddleware(onFulfilled) {
        return instance.interceptors.response.use(
            onFulfilled,
            function (error) {
                onFulfilled(error.response);
                return Promise.reject(error);
            }
        );
    }

    /**
     * Remove response middleware
     *
     * @param {Number} id
     * @returns void
     */
    static removeResponseMiddleware(id) {
        instance.interceptors.response.eject(id);
    }


    /**
     * Refresh the access token using the refresh token
     *
     * @returns {Promise<Object>} New tokens
     */
    static async refreshTokens() {
        let refreshToken = localStorage.getItem('refresh_token'); // Retrieve refresh token from localStorage
        if (!refreshToken) {
            console.log('No refresh token available')
        }
        refreshToken = refreshToken.replace(/^"|"$/g, "");
        try {
            const response = await instance.post('/client/refresh', { refreshToken }); // Replace with your refresh endpoint
            return response.data.data;
        } catch (error) {
            TokenService.clear();
            StorageService.delete('refresh_token');
            StorageService.delete('settings');
            StorageService.delete('user');
            window.location.href = '/product';
        }
    }
    static isRefreshing = false; // Flag to track token refresh state
    /**
     * Custom request with token refreshing
     *
     * @param {AxiosRequestConfig} config
     * @returns {Promise<AxiosResponse>}
     */
    static async requestWithTokenRefresh(config) {
        try {
            return await instance.request(config);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                if (!this.isRefreshing) { // Check if token refresh is not already in progress
                    this.isRefreshing = true; // Set flag to true to indicate token refresh in progress
                    try {
                        const newTokens = await this.refreshTokens();
                        StorageService.set("authorization", newTokens.token.access_token);
                        StorageService.set("refresh_token", newTokens.token.refresh_token);

                        TokenService.set("Bearer " + newTokens.token.access_token);

                        return await instance.request(config);
                    } catch (refreshError) {
                        console.log('Failed to refresh token')
                    } finally {
                        this.isRefreshing = false; // Reset flag after token refresh is done
                    }
                } else {
                    // If token refresh is already in progress, wait and retry the request
                    await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second
                    return this.requestWithTokenRefresh(config); // Retry the request
                }
            }
            throw error;
        }
    }



    static get(url, config) {
        return this.requestWithTokenRefresh({ method: 'get', url, ...config });
    }

    static delete(url, config) {
        return this.requestWithTokenRefresh({ method: 'delete', url, ...config });
    }

    static post(url, data, config) {
        return this.requestWithTokenRefresh({ method: 'post', url, data, ...config });
    }

    static put(url, data, config) {
        return this.requestWithTokenRefresh({ method: 'put', url, data, ...config });
    }

    static patch(url, data, config) {
        return this.requestWithTokenRefresh({ method: 'patch', url, data, ...config });
    }
}

export default ApiService;
