import {SET_USER_MOBILE, SET_USER_PASSWORD} from "../actions/types/userTypes";

const initialState = {
    mobile: undefined,
};

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_MOBILE:
            return {
                ...state,
                mobile: action.payload,
            };
            case SET_USER_PASSWORD:
            return {
                ...state,
                password: action.payload,
            };
        // we can add more action if needed
        default:
            return state;
    }
};

export default userReducer;
