import {
    CARTS_LIST_COUNTER,
    SET_CARTS_LIST,
    SET_PRODUCTS,
    SET_PRODUCTS_CATEGORY, SET_PRODUCTS_SEARCH, SET_PRODUCTS_SEARCH_LOADING, SHOW_CONFIRM_SHOPPING_BTN
} from "../actions/types/productsTypes";


const initialState = {
    productsCategory: undefined,
    cardsList: [],
    cardsListCounter: 0,
    products: [],
    productsSearch: [],
    productsSearchLoading: undefined,
    productsListCounter: 0,
    showConfirmShoppingBtn: false,
};

const productsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRODUCTS:
            return {
                ...state,
                products: action.payload,
            };
            case SET_PRODUCTS_SEARCH:
            return {
                ...state,
                productsSearch: action.payload,
            };
            case SET_PRODUCTS_SEARCH_LOADING:
            return {
                ...state,
                productsSearchLoading: action.payload,
            };
        case SET_PRODUCTS_CATEGORY:
            return {
                ...state,
                productsCategory: action.payload,
            };
        case SET_CARTS_LIST:
            return {
                ...state,
                cardsList: action.payload,
            };
        case CARTS_LIST_COUNTER:
            return {
                ...state,
                cardsListCounter: action.payload,
            };
            case SHOW_CONFIRM_SHOPPING_BTN:
            return {
                ...state,
                showConfirmShoppingBtn: action.payload,
            };
        default:
            return state;
    }
};

export default productsReducer;
