import TokenService from "../../services/token.service";
import HttpHeader from "../../enums/HttpMethode";

/**
 * Handle authentication error
 *
 * @param {AxiosResponse} request
 * @returns {AxiosResponse}
 */
function addAuthTokenToRequest (request) {
    if (TokenService.isExist()) {
        request.headers[HttpHeader.AUTHORIZATION] = TokenService.get();
    }

    return request;
}
export default addAuthTokenToRequest;
