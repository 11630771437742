
import ApiService from './services/ApiService';
import HttpHeader from './enums/HttpMethode';
import MimeType from './enums/MimeType';

import 'leaflet/dist/leaflet.css';


import 'swiper/css';

import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap-rtl/dist/css/bootstrap-rtl.min.css';

import "bootstrap-icons/font/bootstrap-icons.css";
import AuthenticateUser from "./routes/middleware/AuthenticateUser";
import StorageService from "./services/storage.service";
import './assets/scss/app.scss';

ApiService.setHeader(HttpHeader.AUTHORIZATION, StorageService.get("access_token"));
ApiService.setHeader(HttpHeader.CONTENT_TYPE, MimeType.APPLICATION_JSON);
ApiService.addRequestMiddleware(AuthenticateUser);