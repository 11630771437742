import {notistackRef} from "../context/SnackbarProvider";

export const SnackBarDuration = {
    Short: 2000,
    Medium: 3000,
    Long: 4000,
    Infinite: null,
};

class SnackbarService {
    static get snackbar() {
        return notistackRef.current;
    }

    // converts <br> tag to /n
    static br2nl(str) {
        return str.replace(/<br\s*\/?>/mg, "\n");
    }

    static error(msg, options) {
        notistackRef.current?.enqueueSnackbar(this.br2nl(msg), {
            variant: "error",
            style: { whiteSpace: 'pre-line',fontWeight:500 },
            ...options,
        });
    }

    static success(msg, options) {
        notistackRef.current?.enqueueSnackbar(this.br2nl(msg), {
            variant: "success",
            style: { whiteSpace: 'pre-line',fontWeight:500 },
            ...options,
        });
    }

    static info(msg, options) {
        notistackRef.current?.enqueueSnackbar(this.br2nl(msg), {
            variant: "info",
            style: { whiteSpace: 'pre-line',fontWeight:500 },
            ...options,
        });
    }

    static warning(msg, options) {
        notistackRef.current?.enqueueSnackbar(this.br2nl(msg), {
            variant: "warning",
            style: { whiteSpace: 'pre-line',fontWeight:500 },
            ...options,
        });
    }

    static default(msg, options) {
        notistackRef.current?.enqueueSnackbar(this.br2nl(msg), {
            variant: "default",
            style: { whiteSpace: 'pre-line',fontWeight:500 },
            ...options,
        });
    }
}

export default SnackbarService;
