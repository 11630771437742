import './App.css';
import ErrorBoundary from "./components/ErrorBoundary";
import RoutesGenerator from "./routes/RoutesGenerator";
import { Provider } from 'react-redux';
import { Store } from './redux/store/store';
import SnackbarProvider from "./context/SnackbarProvider";

function App() {
    return (
        <div className="app-container">
            <Provider store={Store}>
                    <ErrorBoundary>
                        <SnackbarProvider>
                            <RoutesGenerator/>
                        </SnackbarProvider>
                    </ErrorBoundary>
            </Provider>
        </div>
    );
}

export default App;
