import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './boot';
import { I18nextProvider } from 'react-i18next';
import i18next from './locales/language'; // Import your i18next instance

if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/sw.js').then(function(registration) {
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
        }, function(err) {
            console.log('ServiceWorker registration failed: ', err);
        });
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <I18nextProvider i18n={i18next}>
        <App/>
    </I18nextProvider>
);

