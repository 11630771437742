import { SET_ORDER } from "../actions/types/orderTypes";

const initialState = {
    order: {
        payment_method: undefined,
        delivery_at: undefined,
        delivery_method: 2,
        store_branch_id: undefined,
        address_id: undefined,
        discount_id: undefined,
        transaction_code: undefined,
    },
};

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ORDER:
            return {
                ...state,
                order: {
                    ...state.order,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};


export default orderReducer;
