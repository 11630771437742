import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route , Navigate  } from "react-router-dom";
import * as Pages from "./page";
import RoutesUrl from "./RoutesUrl";





function RoutesGenerator() {
    return (
        <BrowserRouter>
            <Suspense fallback={<></>}>
                <Routes>
                    <Route path={RoutesUrl.dashboard} element={<Pages.Dashboard />} />
                    <Route path={RoutesUrl.profile} element={<Pages.Profile />} />
                    <Route path={RoutesUrl.product} element={<Pages.Product />} />
                    <Route path={RoutesUrl.order} element={<Pages.Order />} />
                    <Route path="*" element={<Navigate to={RoutesUrl.dashboard} />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default RoutesGenerator;
